import Grid from '@material-ui/core/Grid'
import InfoBox from './InfoBox'
import {useBoxesStyles} from './styles'

const infoBoxData = [
  {
    iconName: 'mc-home',
    title_en: 'Register your chalet',
    subtitle_en:
      'We are proud to group the cottages, villas and mansions the most exceptional Quebec.',
    title_fr: 'Affichez votre chalet',
    subtitle_fr:
      'Affichez gratuitement votre chalet puis bénéficiez d’une visibilité accrue, d’un revenu locatif plus élevé et d’une équipe d’experts en location de villégiature.',
  },
  {
    iconName: 'mc-payment',
    title_en: 'Secure Payments',
    subtitle_en:
      'Book your cottage online by performing a deposit of 30% of the renatal amount.',
    title_fr: 'Réservez en toute quiétude',
    subtitle_fr:
      'Naviguez parmi une sélection de chalets à louer au Québec, puis réservez en ligne en toute confiance grâce à un système de paiement vérifié.',
  },
  {
    iconName: 'mc-academy',
    title_en: 'Academy',
    subtitle_en:
      'Find out how to own a rental chalet.Click here to access our online training center!',
    title_fr: 'Devenez propriétaire d’un chalet locatif',
    subtitle_fr:
      'Vous rêvez d’atteindre la liberté financière grâce à la location de votre chalet? Participez à la formation de l’Académie MonsieurChalets.',
  },
]

export default function InfoBoxesDesktop() {
  const classes = useBoxesStyles()

  return (
    <div className={classes.container}>
      <Grid container spacing={3}>
        {infoBoxData.map((info) => (
          <Grid xs={12} md={4} item key={info.title_en}>
            <InfoBox {...info} />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

import {useTranslation} from 'next-i18next'
import AppBaseContainer from 'src/components/elements/AppBaseContainer'
import AppTypography from 'src/components/elements/typography/AppTypography'
import DiscoverCard from 'src/components/modules/discoverCard/DiscoverCard'
import AppLink from 'src/components/elements/Link'
import {useSmallSize} from 'src/hooks/screenSize'
import {useCurrentLocale} from 'src/hooks/locale'
import {
  PROPERTY_PAGE_CHALETS_A_LOUER,
  PROPERTY_PAGE_COTTAGE_RENTAL,
} from 'src/constants/route'
import {LOCALE_EN} from 'src/constants/locale'
import {properties} from './data'
import {useStyles} from './styles'

export default function DiscoverProperties() {
  const {t} = useTranslation('common')

  const classes = useStyles()
  const isSmallSize = useSmallSize()
  const currentLocale = useCurrentLocale()

  const baseURL =
    currentLocale === LOCALE_EN
      ? `en/${PROPERTY_PAGE_COTTAGE_RENTAL}`
      : PROPERTY_PAGE_CHALETS_A_LOUER

  return (
    <div className={classes.sliderWrap}>
      <div className={classes.background}>
        <AppBaseContainer fluid>
          <div className={classes.container}>
            <AppTypography
              variant={isSmallSize ? 'displayLarge' : 'displayXLarge'}
              neutralColor={900}
              className={classes.title}
              component="h2"
            >
              {t('discover_regions')}
            </AppTypography>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {properties.map((property, index) => {
                const linkToSet =
                  currentLocale === LOCALE_EN ? property.link_en : property.link
                return (
                  <div key={index}>
                    <AppLink
                      key={linkToSet}
                      href={`${property.isSpecial ? '' : baseURL}${linkToSet}`}
                    >
                      <DiscoverCard
                        key={index}
                        label_en={property.label_en}
                        label_fr={property.label_fr}
                        description_en={property.description_en}
                        description_fr={property.description_fr}
                        className={classes.image}
                        classes={classes}
                        image={property.image}
                      />
                    </AppLink>
                  </div>
                )
              })}
            </div>
          </div>
        </AppBaseContainer>
      </div>
    </div>
  )
}

import {makeStyles} from '@material-ui/core/styles'

export const useBoxStyles = makeStyles(({breakpoints, spacing}) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    paddingTop: spacing(3),
    textAlign: 'center',
  },
  subTitle: {
    paddingTop: spacing(1),
    textAlign: 'center',
  },
}))

export const useBoxesStyles = makeStyles(({breakpoints, spacing}) => ({
  container: {
    marginTop: '220px',
    [breakpoints.up('xs')]: {
      marginTop: '180px',
    },
    [breakpoints.up('md')]: {
      marginTop: spacing(8),
    },
  },
}))

import {useSmallSize} from 'src/hooks/screenSize'
import InfoBoxesDesktop from './InfoBoxesDesktop'
import AppBaseContainer from 'src/components/elements/AppBaseContainer'

export default function Info() {
  const isSmallSize: boolean = useSmallSize()

  const COMP = isSmallSize ? InfoBoxesDesktop : InfoBoxesDesktop

  return (
    <AppBaseContainer>
      <COMP />
    </AppBaseContainer>
  )
}

import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles(
  ({palette, breakpoints, spacing}) => ({
    root: {
      marginTop: 100,
      marginBottom: 100,
    },
    button: {
      height: 'auto',
      padding: '12px 61.5px',
      margin: '48px auto 0 auto',
      display: 'block',
      [breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    background: {
      background: palette.background.default,
      borderRadius: spacing(0, 0, 2, 2),
      height: 396,
    },
    container: {
      marginTop: -316,
      [breakpoints.down('md')]: {
        padding: spacing(0, 2),
      },
      [breakpoints.up('xl')]: {
        padding: 0,
      },
    },
    subTitle: {
      margin: spacing(1, 0, 6, 0),
    },
    title: {
      [breakpoints.down('sm')]: {
        marginTop: spacing(15),
      },
      [breakpoints.down('xs')]: {
        marginTop: spacing(140 / 8),
      },
    },
  }),
  {index: 1},
)

import {PageProps} from 'src/types/page'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import AppLink from 'src/components/elements/Link'
import {useTranslation} from 'next-i18next'
import {DEFAULT_LOCALE} from 'src/constants/locale'
import {BASE_URL} from 'src/constants/route'
import useStyles from './styles'
import {useCurrentLocale} from 'src/hooks/locale'
import clsx from 'clsx'

function getBreadcrumbTitle(page: PageProps): string | null {
  if (page.breadcrumb_title) {
    return page.breadcrumb_title
  }

  if (page.meta_title) {
    return page.meta_title
  }

  return page.h1_title
}

function useBreadcrumbs(page?: PageProps) {
  const {t} = useTranslation('common')
  const currentLocale = useCurrentLocale()

  if (!page) {
    return []
  }

  const homePageLink =
    currentLocale === DEFAULT_LOCALE ? '/' : `/${currentLocale}`

  return [
    {
      text: t('Home'),
      url: `${BASE_URL}${homePageLink}`, //home page
    },
    ...page.breadcrumbs, //add in between
  ]
}

export function useBreadcrumbsPageSchema(props: {
  page?: PageProps
  currentPageUrl: string
}) {
  const finalBreadCrumbs = useBreadcrumbs(props.page)

  if (!props.page) {
    return null
  }

  const pageTitle = getBreadcrumbTitle(props.page)

  const currentPageBreadcrumbs = {
    text: pageTitle,
    url: props.currentPageUrl, //current path
  }

  const breadcrumbSchemaData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    name: `MonsieurChalets | ${pageTitle}`,
    itemListElement: [...finalBreadCrumbs, currentPageBreadcrumbs].map(
      (b, idx) => {
        return {
          '@type': 'ListItem',
          position: idx + 1,
          name: b.text,
          item: b.url,
        }
      },
    ),
  }

  if (props.page.breadcrumbs.length === 0) {
    return null
  }

  return breadcrumbSchemaData
}

export function PageBreadcrumb(props: {
  page: PageProps
  color?: 'black' | 'white'
}) {
  const {page, color = 'white'} = props

  const classes = useStyles({})
  const finalBreadCrumbs = useBreadcrumbs(page)

  if (finalBreadCrumbs.length === 0) {
    return null
  }

  return (
    <div className="mb-2">
      <Breadcrumbs
        className={clsx(
          classes.breadcrumb,
          color === 'black' ? classes.blackBreadcrumb : '',
        )}
        separator={
          <span
            className={`h-2.5 w-px ${
              color === 'black' ? 'bg-primary-main' : 'bg-gray-300'
            }`}
          />
        }
      >
        {finalBreadCrumbs.map((b, idx) => {
          return (
            <AppLink key={idx} color="inherit" href={b.url}>
              <span
                className={clsx(
                  classes.breadcrumb,
                  color === 'black' ? classes.blackBreadcrumb : '',
                )}
              >
                {b.text}
              </span>
            </AppLink>
          )
        })}
        <BreadcrumbTitle color={color} page={page} />
      </Breadcrumbs>
    </div>
  )
}

function BreadcrumbTitle(props: {page: PageProps; color?: 'black' | 'white'}) {
  const classes = useStyles({})
  const breadcrumbTitle = getBreadcrumbTitle(props.page)

  if (!breadcrumbTitle) {
    return null
  }

  return (
    <span
      className={clsx(
        classes.breadcrumb,
        props.color === 'black' ? classes.blackBreadcrumb : '',
      )}
    >
      {breadcrumbTitle}
    </span>
  )
}

import {makeStyles, Theme} from '@material-ui/core/styles'

const useStyles = makeStyles<
  Theme,
  {noHero?: boolean; maxWidth?: boolean | string}
>(
  ({breakpoints, spacing, palette}) => ({
    container: (params = {}) => ({
      paddingLeft: params.maxWidth ? 0 : spacing(2),
      paddingRight: params.maxWidth ? 0 : spacing(2),
      paddingTop: params.noHero ? spacing(10) : 0,
      paddingBottom: params.noHero ? spacing(10) : 0,
      [breakpoints.down('lg')]: {
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
      },
      [breakpoints.down('sm')]: {
        paddingTop: params.noHero ? spacing(11) : 0,
        paddingBottom: params.noHero ? spacing(11) : 0,
      },

      '& > div:not(.p-0)': {
        paddingTop: spacing(4),
        paddingBottom: spacing(4),
        [breakpoints.down('sm')]: {
          paddingTop: spacing(2),
          paddingBottom: spacing(2),
        },

        //make space for search bar
        '&:nth-child(1)': {
          [breakpoints.down('sm')]: {
            // paddingTop: spacing(12),
            paddingTop: 70,
          },
          [breakpoints.down(425)]: {
            // paddingTop: spacing(14),
            paddingTop: 70,
          },
        },
      },
    }),
    containerSpacing: {
      padding: spacing(0, 2),
    },
    breadcrumb: {
      color: 'white',
      fontSize: 14,
      [breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    blackBreadcrumb: {
      color: palette.primary.main,
      fontWeight: 400,
    },
  }),
  {index: 1}, //fix issue with jss behing under mUi stuff...
)

export default useStyles

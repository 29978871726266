import {makeStyles} from '@material-ui/core/styles'
import {interFontFamily} from 'src/components/materialTheme/typography'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({palette, breakpoints, spacing}) => ({
  subTitle: ({white, left}: {white: boolean; left: boolean}) => ({
    // display: 'flex',
    // alignItems: 'center',
    display: 'block',
    margin: '0 auto',
    fontFamily: interFontFamily,
    fontWeight: 400,
    gap: spacing(1),
    marginTop: spacing(1.5),
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '-0.02em',
    width: '100%',
    color: white ? palette.common.white : palette.text.primary,
    textAlign: left ? 'left' : 'center',
    // [breakpoints.down('md')]: {
    //   maxHeight: 32,
    //   overflow: 'hidden',
    //   display: '-webkit-box',
    //   '-webkit-line-clamp': 1,
    //   '-webkit-box-orient': 'vertical',
    // },
    [breakpoints.up('sm')]: {
      fontSize: 18,
      lineHeight: '32px',
    },
    [breakpoints.up('md')]: {
      fontSize: 18,
      lineHeight: '32px',
    },
  }),
}))

export default function SubTitle(props: {
  subTitle?: string
  children?: React.ReactElement
  white?: boolean
  left?: boolean
}) {
  const {subTitle, children, white = false, left = false} = props

  const classes = useStyles({white, left})

  return (
    <span className={classes.subTitle}>
      {subTitle}
      {children}
    </span>
  )
}

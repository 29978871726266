import {useTranslation} from 'next-i18next'
import Typography from '@material-ui/core/Typography'
import {ClassNameMap} from '@material-ui/styles'
import {makeStyles} from '@material-ui/core/styles'
import AppLink from 'src/components/elements/Link'
import AppIcon from 'src/components/elements/icons/AppIcon'
import {useCurrentLocale} from 'src/hooks/locale'
import {useSmallSize} from 'src/hooks/screenSize'
import {LOCALE_FR} from 'src/constants/locale'
import AppTypography from 'src/components/elements/typography/AppTypography'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({palette, spacing}) => ({
  link: {
    color: palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(1),
  },
  image: {
    borderRadius: '6px !important',
  },
}))

interface Props {
  image: string
  label?: string
  description?: string
  label_en?: string
  label_fr?: string
  style?: React.CSSProperties
  className?: string
  classes?: ClassNameMap
  children?: React.ReactNode
  description_en?: string
  description_fr?: string
  link?: string
}

export default function DiscoverCard({
  image,
  label,
  description,
  label_en,
  label_fr,
  classes,
  children,
  description_en,
  description_fr,
  link,
  ...otherProps
}: Props) {
  const {t} = useTranslation('common')
  const isSmallSize = useSmallSize()
  const classNames = useStyles()

  const currentLocale = useCurrentLocale()

  const activeLabel = label
    ? label
    : currentLocale === LOCALE_FR
    ? label_fr
    : label_en
  const activeDescription = description
    ? description
    : currentLocale === LOCALE_FR
    ? description_fr
    : description_en

  return (
    <div className={classes?.discoverCardRoot}>
      <img loading="lazy" src={image} alt={label} {...otherProps} />
      <div className={classes?.textWrapper}>
        <Typography
          variant={isSmallSize ? 'body2' : 'body1'}
          className={classes?.discoverCardRootItemTitle}
        >
          {activeLabel}
        </Typography>
        <Typography
          variant={'body1'}
          color="textSecondary"
          className={classes?.discoverCardRootSubtitleTitle}
        >
          {activeDescription}
        </Typography>
        {children}
        {link ? (
          <AppLink href={link} className={classNames.link}>
            {t('learn_more')}
            <AppIcon name="arrow-right-s-line-1" />
          </AppLink>
        ) : (
          <AppTypography variant="displayXSmall" className={classNames.link}>
            {t('learn_more')}
            <AppIcon name="arrow-right-s-line-1" />
          </AppTypography>
        )}
      </div>
    </div>
  )
}

import AppIcon from 'src/components/elements/icons/AppIcon'
import AppTypography from 'src/components/elements/typography/AppTypography'
import {useCurrentLocale} from 'src/hooks/locale'
import {useBoxStyles} from './styles'
import clsx from 'clsx'

interface InfoBoxProps {
  iconName: string
  title_en: string
  subtitle_en: string
  title_fr: string
  subtitle_fr: string
}

const InfoBox: React.FC<InfoBoxProps> = ({
  title_en,
  subtitle_en,
  title_fr,
  subtitle_fr,
  iconName,
}) => {
  const classes = useBoxStyles()

  const currentLocale = useCurrentLocale()

  return (
    <div className={classes.container}>
      <div>
        <AppIcon name={iconName} />
      </div>
      <AppTypography
        variant="displayMedium"
        neutralColor={900}
        className={classes.title}
        component="h2"
      >
        {currentLocale === 'fr' ? title_fr : title_en}
      </AppTypography>
      <AppTypography
        className={clsx(classes.subTitle, 'text-text-secondary')}
        component="p"
        variant="body"
      >
        {currentLocale === 'fr' ? subtitle_fr : subtitle_en}
      </AppTypography>
    </div>
  )
}

export default InfoBox

export const properties = [
  {
    label_en: 'Cottages for rent with spa',
    description_en:
      'Discover more than 150 prestigious chalets for rent with spa and enjoy a real moment of relaxation with your loved ones.',
    label_fr: 'Chalets à louer avec spa',
    description_fr:
      'Découvrez plus de 150 chalets de prestige à louer avec spa et profiter d’un véritable moment de relaxation avec vos proches.',
    image: '/images/discover-regions/rent-with-spa.webp',
    link: '/chalets-a-louer-avec-spa',
    link_en: '/cottages-to-rent-with-spa',
    isSpecial: true,
  },
  {
    label_en: 'Cottages for rent Charlevoix',
    description_en:
      'Enjoy our luxurious chalets for rent with panoramic views of the St. Lawrence River, relaxation in the mountains and tranquility in nature.',
    label_fr: 'Chalets à louer Charlevoix',
    description_fr:
      'Profitez de nos luxueux chalets à louer avec vue panoramique sur le fleuve Saint-Laurent, détente en montagne et quiétude en nature.',
    image: '/images/discover-regions/rent-charlevoix.webp',
    link: '/charlevoix',
    link_en: '/charlevoix',
  },
  {
    label_en: 'Cottages for rent Stoneham',
    description_en:
      'Over 120 chalets for rent in the mountains for outdoor enthusiasts only 20 minutes from Québec city.',
    label_fr: 'Chalets à louer Stoneham',
    description_fr:
      'Plus de 120 chalets de ski à louer en montagne pour les amateurs de plein air à seulement 20 minutes de Québec.',
    image: '/images/discover-regions/rent-stoneham.webp',
    link: '/capitale-nationale/stoneham',
    link_en: '/capitale-nationale/stoneham',
  },
  {
    label_en: 'Luxury chalets for rent',
    description_en:
      'Refined selection of high-end chalets, villas and manors across Quebec. Treat yourself to the dream for a stay at these prestigious residences.',
    label_fr: 'Chalets de luxe à louer',
    description_fr:
      'Sélection raffinée des chalets, villa et manoir haut de gamme à travers le Québec. Offrez-vous le rêve le temps d’un séjour à ces prestigieuses résidences.',
    image: '/images/discover-regions/luxury-chalets-for-rent.webp',
    link: '/chalets-de-luxe-a-louer',
    link_en: '/luxury-cottages-to-rent',
    isSpecial: true,
  },
  {
    label_en: 'Cottages for rent Laurentides',
    description_en:
      'Between mountains, lakes and rivers, our chalets for rent in the Laurentians will offer you a real experience in nature.',
    label_fr: 'Chalets à louer Laurentides',
    description_fr:
      'Entre montagne, lacs et rivières, nos chalets à louer dans les Laurentides vous offriront une véritable expérience en nature.',
    image: '/images/discover-regions/cottages-for-rent-laurentides.webp',
    link: '/laurentides',
    link_en: '/laurentides',
  },
  {
    label_en: 'Cottages for rent Lanaudiere',
    description_en:
      'Discover our selection of luxury chalets in the Lanaudière region, ideal for outdoor enthusiasts.',
    label_fr: 'Chalets à louer Lanaudière',
    description_fr:
      'Découvrez notre sélection de luxueux chalets dans la région de Lanaudière, idéal pour les amateurs de plein air.',
    image: '/images/discover-regions/cottages-for-rent-lanaudiere.webp',
    link: '/lanaudiere',
    link_en: '/lanaudiere',
  },
  {
    label_en: 'Small cabins for rent',
    description_en:
      'More than 75 small cabins to discover with your family or as a couple across the province of Québec.',
    label_fr: 'Mini-chalets à louer',
    description_fr:
      'Plus de 75 mini-chalets à découvrir avec votre famille ou en amoureux à travers la province de Québec.',
    image: '/images/discover-regions/small-cabins-for-rent.webp',
    link: '/mini-chalets-a-louer',
    link_en: '/tiny-cottages-for-rent',
    isSpecial: true,
  },
  {
    label_en: 'Log cabins',
    description_en:
      'Discover many log cabins to rent with your loved ones, ideal for a moment in nature.',
    label_fr: 'Chalets en bois rond',
    description_fr:
      'Découvrez de nombreux chalets en bois rond à louer avec vos proches, idéal pour un moment en nature.',
    image: '/images/discover-regions/log-cabins.webp',
    link: '/chalets-en-bois-rond',
    link_en: '/log-cabins-for-rent',
    isSpecial: true,
  },
  {
    label_en: 'Waterfront cabins for rent',
    description_en:
      'Choose one of our many waterfront cabins for your next stay.',
    label_fr: `Chalets à louer bord de l'eau`,
    description_fr: `Choisissez l'un de nos nombreux chalets au bord de l'eau pour votre prochain séjour.`,
    image: '/images/discover-regions/waterfront-cabins-for-rent.webp',
    link: '/chalets-a-louer-bord-de-leau',
    link_en: '/cottages-to-rent-waterfront',
    isSpecial: true,
  },
  {
    label_en: 'Québec Cabins for rent',
    description_en:
      'More than 500 cabins for rent around the province of Québec',
    label_fr: 'Chalets à louer Québec',
    description_fr:
      'Plus de 500 chalets à louer à travers la province de Québec',
    image: '/images/discover-regions/quebec-cabins-for-rent.webp',
    link: '/chalets-a-louer-quebec',
    link_en: '/cottages-to-rent-quebec',
    isSpecial: true,
  },
  {
    label_en: 'Mauricie Cabins for rent',
    description_en: 'Discover our cabins for rent in the Mauricie region',
    label_fr: 'Chalets à louer Mauricie',
    description_fr: `Découvrez l'ensemble de nos chalets à louer dans la région de la Mauricie`,
    image: '/images/discover-regions/mauricie-cabins-for-rent.webp',
    link: '/mauricie',
    link_en: '/mauricie',
  },
  {
    label_en: 'Estrie Cabins for rent',
    description_en:
      'Stay in the Estrie region in one of our many cabins for rent',
    label_fr: 'Chalets à louer Estrie',
    description_fr: `Séjournez dans la région de l'Estrie dans l'un de nos nombreux chalets à louer`,
    image: '/images/discover-regions/estrie-cabins-for-rent.webp',
    link: '/estrie',
    link_en: '/estrie',
  },
]

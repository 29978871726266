import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {interFontFamily} from 'src/components/materialTheme/typography'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({palette, breakpoints, spacing}) => ({
  title: ({white, left}: {white: boolean; left: boolean}) => ({
    position: 'relative',
    display: 'block',
    margin: '0 auto',
    fontWeight: 400,
    fontFamily: interFontFamily,
    fontSize: '40px',
    lineHeight: '50px',
    letterSpacing: '-0.02em',
    color: white ? palette.common.white : palette.text.primary,
    textAlign: left ? 'left' : 'center',

    '& span.-bolder': {
      fontWeight: 600,
    },
    '& br': {
      display: 'inline-block',
    },
    '& br.-desktop-only': {
      display: 'none',
    },
    // [breakpoints.down('md')]: {
    //   maxHeight: 180,
    //   overflow: 'hidden',
    //   display: '-webkit-box',
    //   '-webkit-line-clamp': 3,
    //   '-webkit-box-orient': 'vertical',
    // },
    // [breakpoints.down('sm')]: {
    //   maxWidth: '50%',
    // },
    [breakpoints.up('sm')]: {
      fontSize: '50px',
      lineHeight: '60px',
      '& br.-mobile-only': {
        display: 'none',
      },
      '& br.-desktop-only': {
        display: 'inline-block',
      },
    },
    [breakpoints.up('md')]: {
      fontSize: '55px',
      lineHeight: '65px',
    },
  }),
}))

export default function Title(props: {
  title?: string
  children?: React.ReactNode
  white?: boolean
  left?: boolean
}) {
  const {title, children, white = false, left = false} = props

  const classes = useStyles({white, left})

  if (!title) {
    return null
  }

  return (
    <Typography variant="h1" component="h1" className={classes.title}>
      <span dangerouslySetInnerHTML={{__html: title}} />
      {children}
    </Typography>
  )
}
